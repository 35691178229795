<template>
    <div class="FAQ-list">
        <div class="modal-header">
            <button
                class="btn modal-back"
                type="button"
                aria-label="Back to FAQs"
                v-if="FAQListData.length && emailFormVisible"
                @click="setEmailFormVisible(false)">
                <svg class="modal-icon icon-navy">
                    <use :xlink:href="backIcon"></use>
                </svg>
            </button>
            <h2>ECoach Help</h2>
            <button
                class="btn modal-close"
                type="button"
                aria-label="Close ECoach Help modal"
                @click="closeAlert">
                <svg class="modal-icon icon-navy">
                    <use :xlink:href="exitIcon"></use>
                </svg>
            </button>
        </div>
        <template v-if="!loading">
          <form class="support-email survey" v-if="emailFormVisible || !FAQListData.length" novalidate>
              <h3 v-if="!FAQListData.length">In need of ECoach help? <b>Contact us.</b></h3>
              <h3 v-else>Contact us</h3>
              <div class="survey_question">
                  <label class="survey_prompt" for="subject">Subject <span class="required-item">*</span></label>
                  <ul class="errorlist" v-if="errors.subject.length">
                      <li v-for="(error, index) in errors.subject" :key="index">{{ error }}</li>
                  </ul>
                  <div class="survey_responses">
                      <textarea id="subject" v-model="emailSubject" ref="subject" required></textarea>
                  </div>
              </div>
              <div class="survey_question">
                  <label class="survey_prompt" for="message">Message <span class="required-item">*</span></label>
                  <ul class="errorlist" v-if="errors.message.length">
                      <li v-for="(error, index) in errors.message" :key="index">{{ error }}</li>
                  </ul>
                  <div class="survey_responses long-response">
                      <textarea id="message" v-model="emailInput" required></textarea>
                  </div>
              </div>
              <div class="button-block">
                  <button class="btn" type="submit" @click="dispatchEmail" value="sendEmail">Submit</button>
              </div>
          </form>
          <template v-else>
              <div class="content">
                  <p>In need of ECoach help? Review the FAQs below to get the answers you need. <b>Don’t see your question here?</b></p>
              </div>
              <div class="button-block">
                  <button class="btn"
                      @click="setEmailFormVisible(true)">
                      Contact us
                  </button>
              </div>
              <h3>FAQs</h3>
              <div class="content">
                  <div class="accordion vue-accordion">
                      <button
                          class="accordion-group"
                          :class="dynamicClass(FAQ)"
                          type="button"
                          v-for="(FAQ, index) in FAQListData"
                          :key="FAQ.id"
                          ref="faq"
                          @click="toggleAccordion(FAQ)">
                          <h3>
                              {{FAQ.question}}
                              <span class="ui-accordion-header-icon"></span>
                          </h3>
                          <div class="accordion-content" v-html="FAQ.answer"></div>
                      </button>
                  </div>
              </div>
          </template>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useFaqStore } from '../../stores/faq.js';

import { exitIcon, backIcon } from "../../images.js";

export default({
    name: 'FAQList',
    data () {
      return {
        loading: false,
        openAccordions: [],
        emailSubject: '',
        emailInput: '',
        exitIcon: exitIcon,
        backIcon: backIcon,
      };
    },
    methods: {
      ...mapActions(useFaqStore, [
        'sendEmailData',
        'setEmailFormVisible',
        'setErrors',
        'toggleFAQOpenState',
        "sendEmail",
        "loadFAQData",
      ]),
      dynamicClass: function (FAQ) {
        return { active: this.openAccordions.includes(FAQ) };
      },
      toggleAccordion (FAQ) {
        this.openAccordions.includes(FAQ) ? this.openAccordions.pop(FAQ) : this.openAccordions.push(FAQ);
      },
      dispatchEmail: function () {
        this.validate();
        if (!this.errors.subject.length && !this.errors.message.length) {
          this.sendEmail({
            subject: this.emailSubject,
            email_content: this.emailInput
          }).then((response) => {
            this.resetModal();
          });
        }
      },
      validate() {
        this.setErrors({
          subject: this.emailSubject ? [] : ['This field is required.'],
          message: this.emailInput ? [] : ['This field is required.']
        });
      },
      resetModal: function () {
        this.emailSubject = '';
        this.emailInput = '';
      },
      closeAlert: function () {
        this.sendEmailData('');
        this.setEmailFormVisible(false);
        this.toggleFAQOpenState();
        this.setErrors({
          subject: [],
          message: []
        });
      },
      focusInput(){
        if ((this.$refs["faq"] || []).length) {
          this.$refs["faq"][0].focus()
        } else if (this.$refs.subject) {
          this.$refs.subject.focus();
        }
      },
      loadFAQs () {
        this.loading = true;
        this.loadFAQData().then(() => {
          this.loading = false;
          this.focusInput();
        });
      },
    },
    computed: {
      ...mapState(useFaqStore, [
        'FAQListData',
        'emailFormVisible',
        'sentEmail',
        'errors',
      ]),
    },
    mounted() {
      this.loadFAQs();
    }
});
</script>
