import axios from "axios";
import { defineStore } from 'pinia';

import {
  coachBaseUrl,
  institutionBaseUrl,
  coachFaqListURL,
  coachSendEmailURL,
  institutionFaqListUrl,
  institutionSendEmailUrl,
  generalFaqListUrl,
  generalFaqEmailUrl,
  messageCenterUrl,
  coachSurveyUrl,
  institutionSurveyUrl,
  coachHistoryUrl,
  institutionMessageCenterUrl,
  institutionHistoryUrl,
} from "../urls.js";

const coachInfo = coachBaseUrl.match(location.pathname);
const institutionInfo = institutionBaseUrl.match(location.pathname);
const messageCenterInfo = messageCenterUrl.match(location.pathname);
const coachSurveyInfo = coachSurveyUrl.match(location.pathname);
const institutionSurveyInfo = institutionSurveyUrl.match(location.pathname);
const coachHistoryInfo = coachHistoryUrl.match(location.pathname);
const institutionMessageCenterInfo = institutionMessageCenterUrl.match(location.pathname);
const institutionHistoryInfo = institutionHistoryUrl.match(location.pathname);
let faqUrl;
let emailUrl;

if (coachInfo) {
  faqUrl = coachFaqListURL.stringify(coachInfo);
  emailUrl = coachSendEmailURL.stringify(coachInfo);
} else if (institutionInfo) {
  faqUrl = institutionFaqListUrl.stringify(institutionInfo);
  emailUrl = institutionSendEmailUrl.stringify(institutionInfo);
} else if (messageCenterInfo) {
  faqUrl = coachFaqListURL.stringify(messageCenterInfo);
  emailUrl = coachSendEmailURL.stringify(messageCenterInfo);
} else if (coachSurveyInfo) {
  faqUrl = coachFaqListURL.stringify(coachSurveyInfo);
  emailUrl = coachSendEmailURL.stringify(coachSurveyInfo);
} else if (institutionSurveyInfo) {
  faqUrl = institutionFaqListUrl.stringify(institutionSurveyInfo);
  emailUrl = institutionSendEmailUrl.stringify(institutionSurveyInfo);
} else if (coachHistoryInfo) {
  faqUrl = coachFaqListURL.stringify(coachHistoryInfo);
  emailUrl = coachSendEmailURL.stringify(coachHistoryInfo);
} else if (institutionMessageCenterInfo) {
  faqUrl = institutionFaqListUrl.stringify(institutionMessageCenterInfo);
  emailUrl = institutionSendEmailUrl.stringify(institutionMessageCenterInfo);
} else if (institutionHistoryInfo) {
  faqUrl = institutionFaqListUrl.stringify(institutionHistoryInfo);
  emailUrl = institutionSendEmailUrl.stringify(institutionHistoryInfo);
} else {
  faqUrl = generalFaqListUrl.stringify();
  emailUrl = generalFaqEmailUrl.stringify();
}

const initialState = {
  FAQListData: {},
  emailFormVisible: false,
  sentEmail: '',
  errors: {
    subject: [],
    message: [],
  },
  isOpen: false,
  FAQListURL: faqUrl,
  sendEmailURL: emailUrl,
}

export const useFaqStore = defineStore('faq', {
  state: () => initialState,
  actions: {
    updateFAQData (payload) {
      this.FAQListData = payload;
    },
    setEmailFormVisible (status) {
      this.emailFormVisible = status;
    },
    sendEmailData (payload) {
      this.sentEmail = payload;
    },
    toggleFAQOpenState () {
      this.isOpen = this.isOpen === false;
    },
    setErrors ({ subject, message }) {
      this.errors.subject = subject;
      this.errors.message = message;
    },
    async loadFAQData(){
      let response = await axios.get(this.FAQListURL);
      this.updateFAQData(response.data);
      return response;
    },
    async sendEmail(emailInput) {
      return axios.post(this.sendEmailURL, emailInput).then((response) => {
          this.sendEmailData(emailInput);
          this.setEmailFormVisible(false);
      });
    },
  },
});
