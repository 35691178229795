<template>
    <div id="faq-modal">
        <modal :modalClasses="'active FAQ-Modal display-modal'"
            v-if="activeModal"
            v-on:modal-cancel="closeAlert">
            <focus-trap>
              <div v-if="sentEmail" class="modal-info">
                <div class="modal-alert alert-success" role="alert">
                    <div class="message-container">
                        <div class="alert-icon">
                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
                                <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <p class="alert-text">Your ECoach Support request was successfully submitted. You will receive a confirmation email shortly.</p>
                    </div>
                    <button
                        class="btn btn-close"
                        type="button"
                        aria-label="Close ECoach support request confirmation"
                        @click="closeAlert">
                    </button>
                </div>
              </div>
              <faqlist v-else></faqlist>
            </focus-trap>
        </modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useFaqStore } from '../../stores/faq.js';

import Modal from '../generic/Modal.vue';
import FAQList from './FAQList.vue';
import { FocusTrap } from 'focus-trap-vue';

export default({
  name: 'FAQModal',
  data () {
    return {}
  },
  components: {
    modal: Modal,
    faqlist: FAQList,
    FocusTrap,
  },
  methods: {
    ...mapActions(useFaqStore, [
      'toggleFAQOpenState',
      'toggleSentEmailState',
      'setEmailFormVisible',
      'sendEmailData',
      'setErrors',
    ]),
    closeAlert() {
      this.sendEmailData('');
      this.toggleFAQOpenState();
      this.setEmailFormVisible(false);
      this.setErrors({
        subject: [],
        message: []
      });
    },
  },
  computed: {
    ...mapState(useFaqStore, {
      activeModal: 'isOpen',
      sentEmail: 'sentEmail',
    }),
  }
});
</script>
